body {
  font-family: "Muli", sans-serif !important;
  background-color: #f5f6fa !important;
  color: #4F4F4F;
}

#authPage {
  background-image: url("https://res.cloudinary.com/dqzo9yjly/image/upload/v1681763981/backgrounds/uoghww65bdasgxnviwwp.png");
  background-size: cover;
}

#authPage .logo{
  margin-bottom: 4rem
}

#authPage .authButtonPrimary, .login .authButtonPrimary, .forgot-password-page .authButtonPrimary, .register-page .authButtonPrimary {
  height: 40 !important;
  font-size: 14 !important;
  font-weight: 500;
  margin-bottom: 1rem;
  width: 55%;
}

#authPage .authButtonSecondary, .login .authButtonSecondary {
  height: 40;
  font-size: 14;
  font-weight: 500;
  background-color: #A9CB59;
  border-color: #A9CB59;
  margin-bottom: 1rem;
  width: 55%;
}

#authPage .authButtonAlt, .login .authButtonAlt {
  height: 40;
  font-size: 14;
  font-weight: 500;
  font-size: 14;
  background-color: #9A9A9A42;
  border-color: #9A9A9A42;
  width: 55%;
}

#authPage #authOrContainer, .login #authOrContainer  {
  margin-bottom: 1rem;
  padding-left: 22.5%;
  padding-right: 22.5%;
  display: flex;
}

#nochKeinKontoText {
  font-size: 13px;
  color: '#4F4F4F';
  margin-top: 3rem;
}

.navbar {
  padding: 1.5% 2.5%
}

#settings-modal, #settings-modal .modal-content {
  border: none;
  background-color: #C25982;
  border-radius: 10px;
  color: white;
}

#settings-modal .settings-modal-button {
  width: 100%;
  margin: 0px;
}

#settings-modal .settings-modal-button:hover {
  cursor: pointer;
}

#settings-modal p {
  font-size: 13px;
  font-weight: 500;
  margin-top: 3px;
  margin-bottom: 0px;
}

#settings-modal path {
  stroke: white
}

#market-filter-col {
  padding: 0px;
  max-width: 15%;
  margin-top: 1.5rem;
  padding-left: 2.5%;
}

#market-filter-col h3{
  font-size: 20px;
  font-weight: bold;
  color: #171717;
  margin-bottom: 0.75rem;
}

.market-filter {
  margin: 0px;
  padding-top: 0.1rem;
  cursor: pointer;
}

.market-filter .col {
  padding: 0px;
}

.market-filter .market-filter-icon-col {
  max-width: 20%;
}

.market-filter p {
  font-size: 14px;
  color: #727272;
  margin-top: 4px;
  margin-bottom: 0.5rem;
}

.market-filter.active p {
  color: #C25982;
}

.market-filter.active path {
  stroke: #C25982;
}

#market-items-col {
  padding: 0px;
  margin-top: 0.5rem;
}

#single-item-page-subnavigation-row {
  border-bottom: 1px solid #C25982;
  margin-bottom: 1rem;
}

#single-item-page-subnavigation-row .col{
  /* padding: 0; */
}

#single-item-page-subnavigation-row .col.active{
  border-bottom: 3px solid #C25982;
}

#single-item-page-subnavigation-row p{
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: 600;
  color: #616161;
}

#single-item-page-recommendations-row h3 {
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-bottom: 1rem;
}

#single-item-page-recommendations-row .card {
  padding: 0.5rem
}

#single-item-page-recommendations-row .card img{
  border-radius: 6px;
  margin-bottom: 0.2rem;
}

#single-item-page-recommendations-row .card .price{
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: -0.1rem;
}

#single-item-page-recommendations-row .card .name{
  font-size: 10px;
  font-weight: 400;
  color: black;
  margin-bottom: 0px;
}

#single-item-page-recommendations-row .card .origin{
  font-size: 6px;
  font-weight: 400;
  color: black;
  margin-bottom: 0px;
}

#help-page .contact-form {
  margin-top: 24px;
  background-color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-radius: 17px;
}

#help-page .contact-form h2{
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

#help-page .contact-form p{
  font-size: 12px;
  font-weight: 400;
  color: #9B9B9B;
  text-align: center;
  margin-bottom: 2rem;
}

#help-page .contact-form label{
  font-size: 16px;
  font-weight: 400;
  color: #9A9A9A;
}

#help-page .contact-form input{
  margin-bottom: 1rem;
}

#settings-page .header-row h2 {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
  margin-bottom: 2rem;
}

#settings-page h4 {
  font-size: 15px;
  font-weight: 500;
  color: #424242;
  text-align: left;
  margin-bottom: 0.5rem;
}

#settings-page label {
  font-size: 12px;
  color: #727272;
}

#settings-page input {
  font-size: 12px;
}

#settings-page button, #admin-single-item-page button {
  font-size: 12px;
  height: 36px;
  border-radius: 6px;
}

#settings-page .custom-form input, #settings-page .custom-form input .form-control, .admin-single-element-page input, .admin-single-element-page textarea {
  box-shadow: none;
  height: 36px;
  border: 1px solid #9A9A9A !important;
  border-radius: 6px !important;
}

#settings-page .notification-toggle-text {
  font-size: 12px;
  color: #727272;
}

#settings-page .react-toggle-track {
  background-color: #F3F3F3;
  border: 1.5px solid #EAEAEA;
}

#settings-page .react-toggle-thumb {
  background-color: #D1D1D1;
  border: none
}

#settings-page .react-toggle--checked .react-toggle-track {
  background-color: white;
  border: 1.5px solid #EAEAEA
}

#settings-page .react-toggle--checked .react-toggle-thumb {
  background-color: #A9CB59;
  border: none
}

#settings-page #settings-page-orders-links-row .link {
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
}

#settings-page #settings-page-orders-links-row .link p{
  margin-bottom: 0px;
  font-size: 12px;
  color: #727272
}

#settings-page #settings-page-orders-links-row .link svg{
  color: #727272
}

#orders-page h3 {
  font-size: 20px;
  font-weight: bold;
  color: #171717;
  margin-bottom: 0.75rem;
}

#orders-page .order-list-item.active {
  background-color: #E7E7E6;
}

#orders-page .order-list-item img, #marketlists-page .order-list-item img {
  border-radius: 6px;
  max-width: 70px;
}

#orders-page #selected-order-card img, #marketlists-page #selected-order-card img {
  border-radius: 6px;
  max-width: 70px;
  max-height: 70px;
}

#orders-page #selected-order-card, #marketlists-page #selected-order-card {
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}

#orders-page #selected-order-card .label-value, #marketlists-page #selected-order-card .label-value  {
  display: flex;
  flex-direction: row;
}

#orders-page #selected-order-card .label, #marketlists-page #selected-order-card .label {
  font-size: 12px;
  font-weight: 600;
  color: black;
  margin-bottom: 0px;
}

#orders-page #selected-order-card .value, #marketlists-page #selected-order-card .value {  
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #909090
}

#orders-page h3 {
  color: black;
  font-size: 18px;
  font-weight: 800;
}

#orders-page button {
  width: 65%;
  margin-bottom: 1rem;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

#orders-page #selected-order-items p, #marketlists-page #selected-order-items p{
  margin-bottom: 0px;
}

#marketlists-page {
  padding-left: 0px;
  padding-right: 0px;
}

#marketlists-page h3 {
  font-size: 18px;
  font-weight: 800;
  color: black;
  margin-bottom: 0.75rem;
}

#add-marketlist-button button, #add-item-to-marketlist-button button{
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#add-marketlist-button svg, #add-item-to-marketlist-button svg {
  width: 13px;
  height: 13px;
}

#selected-order-item-price-col {
  text-align: right;
}

#remove-item-from-marketlist-btn {
  margin-bottom: 1rem;
}

/* Active textfield border & shadow */
/* input:focus,
textarea:focus,
select:focus {
  border: 1px solid #31394d !important;
  box-shadow: 0 0 4px #31394d !important;
  -moz-box-shadow: 0 0 4px #31394d !important;
  -webkit-box-shadow: 0 0 4px #31394d !important;
} */

/* .main-content {
  min-height: 100% !important;
} */

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.full-width {
  width: 100%;
}

div.page{
  min-height: 90vh;
}

.hf-green {
  color: #ADB835;
}

h4 {
  color: #4a4a4a;
}

h5 {
  font-weight: 600;
}

h4.page-title {
  font-size: 24px;
  font-weight: 800;
  color: #C25982;
}

h4.module-title {
  font-weight: 700;
}

.module-figure {
  text-align: center;
}

.module-figure h4 {
  font-weight: 700;
  font-size: 2rem;
}

.auth-page h2 {
  margin-top: 2rem;
  color: #C25982;
  font-size: 20px;
  font-weight: 400;
}

h5 {
  color: #4a4a4a;
}

button.btn-gradient {
  /* background: linear-gradient(135deg, #2c4b8a, #152340); */
  border: none;
}

.sidebar.open img {
  max-width: 80%;
}

.sidebar.closed img {
  max-width: 100%;
}

.sidebar.closed img.Logo-long {
  display: none;
}

.secondary-navbar {
  background-color: rgba(0, 0, 0, 0.03);
}

nav.primary-nav img#desktop-navbar-logo {
  max-width: 10.25%;
  margin-left: 0.25%;
  margin-right: 5%;
}

.nav-link.navbar-cart-container {
  margin-top: 2.5px;
  border-radius: 8px;
  background-color: #A9CB59;
}

.nav-link.navbar-cart-container {
  color: white;
}

.nav-link.navbar-cart-container:hover {
  color: white;
}

.nav-link.navbar-cart-container .cart-total{
  font-weight: bold;
  text-align: right;
  margin-right: 2px;
}

.nav-link.navbar-cart-container svg{
  padding-bottom: 4px;
}

.nav-link.navbar-cart-container svg path{
  fill: white;
}

/* .secondary-navbar a {
  color: #152340;
  font-weight: 700;
} */

/* .secondary-navbar a:hover {
  color: #152340;
} */

nav.primary-nav {
  background-color: white;
}

.bottom-edge-shadow {
	-webkit-box-shadow: 0 8px 8px -8px #00000020;
	   -moz-box-shadow: 0 8px 8px -8px #00000020;
	        box-shadow: 0 8px 8px -8px #00000020;
}

.secondary-navbar .navbar-nav {
  flex-direction: row !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(21,35,64,1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
.navbar img {
  max-width: 200px;
}

h5.modal-title {
  font-weight: bold;
}

.remove-btn {
  margin-right: 0.5rem;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in;
}

/* Top Navbar */
.primary-nav a {
  color: #909090;
}
.primary-nav a:hover {
  color: #C25982;
}

.

/* Dashboard search bar */
.form-inline .search-field {
  color: #14337e;
  height: 40px;
  background-color: white;
  border: none;
}
/* .form-inline .search-field:focus {
  box-shadow: 0 0 10px lightgray !important;
} */
.form-inline .form-control.sf-textfield {
  border-radius: 7px 0px 0px 7px;
  width: 600px;
}
.btn-secondary.sf-button {
  border-radius: 0px 7px 7px 0px;
  font-weight: 600;
}
.btn-secondary.sf-button:hover {
  color: #14337e;
  background-color: white;
}

/* Dashboard cards */
.row .card {
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 7px;
  padding: 1rem;
  margin-bottom: 1rem;
}

/* Projects List */

li.list-group-item {
  border: none;
  border-radius: 7px;
}
.projects-list a {
  color: #14337e;
}
.projects-list a:hover {
  text-decoration: none !important;
  color: #14337e;
}
.projectlist-float-right {
  float: right;
  border: none;
}
/* Project completion tick */
.projects-list a svg.incomplete {
  color: #dadada;
}
.projects-list a svg.complete {
  color: #1c8526;
}
/* Project paid status badge */
.badge.paid {
  margin-right: 1rem;
  padding: 0.5rem;
  background-color: #4a8950;
  min-width: 90px;
}
.badge.unpaid {
  margin-right: 1rem;
  padding: 0.5rem;
  background-color: #ff635f;
  min-width: 90px;
}
.projectlist-date {
  font-weight: bold;
}

/* Modals */
.modal-body form {
  padding-left: 2rem;
  padding-right: 2rem;
}
.modal-body label {
  color: #4F4F4F;
  font-weight: 500;
}

/* Project modal */
.project-modal .linediv {
  margin-bottom: 1rem;
}
/* .project-modal input {
  border: solid 1px #4b4b4b !important;
  border-radius: 10px;
} */
.project-modal input.input-file {
  border: none !important;
  border-radius: 0;
}
.project-modal button {
  border-radius: 10px;
  max-width: 35%;
  float: right;
}

.tab-pane .tab-button {
  border-radius: 10px;
  max-width: 25%;
  float: right;
}

.ov-tab-date {
  font-size: 90%;
  border: solid #14337e 1px;
  border-radius: 10px;
  padding: 0.5rem;
  color: #979797;
}

.invoice-list button {
  margin-right: 1rem;
  font-size: 90%;
  padding-top: 0.2rem;
  padding-bottom: 0.21rem;
  background-color: #14337e;
}

/* App Sidebar */
.main-content.sidebarOpen {
  width: 100%;
  /* width: 84%; */
  /* margin-left: auto; */
}

.main-content.sidebarClosed {
  width: 95%;
  margin-left: auto;
}

.toggle-sidebar-btn {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.sidebar {
  background-color: white;
  height: 100vh;
  width: 16%;
  position: fixed;
}

.sidebar.closed {
  width: 5%;
}

.sidebar h4 {
  /* font-size: 1.5rem; */
  font-weight: 500;
}

.side-menu {
  margin-top: 1rem;
}

.sidebar.closed li {
  width: 100%;
  margin-top: 0px !important;
}

.sidebar.closed .side-item {
  width: 100%;
}

.sidebar.closed .side-item .sidebar-svg{
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
}

.sidebar .side-item {
  margin: 5px 15px 20px 0px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 0px 4px 4px 0px;
  color: #9A9A9A;
  font-weight: 600;
}

.sidebar .side-item.active {
  background-color: #C25982;
  color: white;
}

.sidebar .side-item.active svg path{
  fill: white;
}

.sidebar-svg {
  margin-left: 1rem;
  margin-right: 1rem;
}

.sidebar-svg svg path {
  fill: #9A9A9A;
}

.sidebar .side-item.active .svg-container {
  background-color: #14337e;
}

.sidebar .side-item:not(.active):hover {
  background-color: #31394d09;
}

.sidebar .new-project-button {
  margin: 5px 15px;
  outline: none;
  border: none;
  border-radius: 8px;
  width: 200px;
}

.sidebar.open hr {
  border: 1px solid #e1e1e1;
  width: 80%;
}

.nav-link div.card {
  display: flex;
  flex-direction: row;
  border-radius: 20px;
  margin-bottom: 0;
}
.nav-link.active div.card {
  background-color: #14337e;
}
.nav-link.active div.card h4 {
  color: white;
}
.nav-link div.card svg {
  width: 18px;
  height: 18px;
}
.sidebar .svg-container {
  background-color: #14337e;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 2rem;
  max-height: 2rem;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}

/*  */

.nav-link.active div.card .svg-container {
  background-color: white;
}
.nav-link.active div.card svg path {
  fill: #14337e;
}
/* New Project Button */
.link-button {
  background: linear-gradient(135deg, #2c4b8a, #152340);
  border-radius: 8px;
  outline: none;
  border: none;
  padding: 0;
}
.secondary-navbar .link-button .nav-link {
  color: white;
}
.link-button a {
  color: white;
}
.link-button:hover,
.link-button:focus {
  background: linear-gradient(135deg, #2c4b8a, #152340);
  outline: none;
  border: none;
  padding: 0;
}

/* ----- */
.project-nav.nav-tabs {
  border-bottom: none;
  font-weight: bold;
  color: #979797;
}

.nav-tabs {
  border-bottom: none;
}

a {
  color: #979797;
}
.project-nav .nav-link {
  border: none !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 2rem !important;
}
.project-nav.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white !important;
  background-color: #C25982 !important;
  border: 1px solid #C25982;
  /* border-bottom: 2px solid #C25982 !important; */
}

.nav-tabs .nav-link.active:hover{
  /* color: #C25982 !important; */
  color: white !important;
}

.nav-tabs .nav-link:hover{
  color: #C25982 !important;
  border: 1px solid #C25982;
}

button.overview-button {
  float: right;
  border: none;
  background-color: transparent;
}

.greyed-out {
  color: #979797;
}

.disabled-text {
  color: #D1D1D1
}

button.overview-button:focus {
  outline: 0;
}

.negative-icon {
  background-color: #fd413c15;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 5rem;
  height: 90%;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}
.negative-icon svg {
  font-size: 3rem;
}
.negative-icon svg path {
  fill: #ff635f;
}
.positive-icon {
  background-color: #1c852615;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 5rem;
  height: 90%;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}
.positive-icon svg {
  font-size: 3rem;
}
.positive-icon svg path {
  fill: #4a8950;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.login-page {
  width: 30% !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login {
  background-color: white;
  height: 100vh;
}

.login-page h3 {
  font-weight: 400;
}
.login-page img {
  width: 65%;
  display: block;
  margin: auto;
}

.login-page button {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.header-icon {
  float: left;
  background-color: #C25982;
  padding: 0.5rem;
  border-radius: 8px;
  max-width: 2rem;
  max-height: 2rem;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}

.more-info-button {
  float: right;
}

.toggle-test {
  float: right !important;
}

button.custom-btn {
  border-radius: 10px;
  max-width: 25%;
  float: right;
}

.center-vertically {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.news-card {
  height: 5rem;
}
.news-card h4 {
  margin: 0;
}
.num-of-news {
  font-weight: bold;
  font-size: 2.5rem;
}

.center {
  text-align: center !important;
}

.panel {
  /* border: none !important;
  border-radius: 8px !important; */
  /* max-width: 430px !important; */
  margin-bottom: 20px;
  /* box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15); */
}

.login-page a.signup-link {
  color: #A9CB59;
}

.login-page a.signup-link:hover {
  text-decoration: none;
  color: #A9CB59;
}

.login-page .form-group {
  text-align: center;
}

.login-page a.forgot-pw-link {
  font-size: 13px;
  color: #4f4f4f;
}

.login-page a:hover {
  text-decoration: none;
}

/* Project Invoice modal */

div.invoice-downloads {
  text-align: center;
}

div.invoice-downloads svg {
  font-size: 2rem;
}
/* button.download-invoice-btn {
  max-width: 100%;
  float: none;
  background: linear-gradient(135deg, #2c4b8a, #152340);
  border: none;
  
  margin-bottom: 1rem;
}

button.download-invoice-btn p {
  margin-bottom: 0px;
}

button.download-invoice-btn p.text-small {
  font-size: 80%;
} */

button.btn-dark {
  background-color: #C25982;
  border: none;
}

button.btn-dark:hover {
  background-color: #C25982;
}

button.btn-dark:focus {
  background-color: #C25982;
}

button.disabled:hover {
  background-color: #343a40;
}

button.btn-dark.disabled:hover {
  background-color: #343a40;
}

button.btn-secondary {
  background-color: #C25982;
  border-color: #C25982;
  border-radius: 8px;
}

button.btn-secondary.disabled:hover {
  background-color: #6c757d;
  border-color: #6c757d;
}

button.btn-secondary:hover {
  background-color: #C25982;
  border-color: #C25982;
}

button.btn-secondary:focus {
  background-color: #C25982;
  border-color: #C25982;
}

#register-success-page .btn-secondary {
  background-color: #C25982;
  border-color: #C25982;
  padding: 10px 20px;
  border-radius: 15px;
}

#register-success-page .btn-secondary:hover {
  background-color: #C25982;
  border-color: #C25982;
  color: white !important;
}

#register-success-page .btn-secondary:focus {
  background-color: #C25982;
  border-color: #C25982;
}

/* List Modal */

.list-modal .modal-body {
  padding: 0px;
}

.list-modal .btn {
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: #4a4a4a;
  border: none;
  border-radius: 0px;
  padding: 1rem;
}

.list-modal .btn:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-modal .btn:active {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-modal .btn:focus {
  background-color: rgba(0, 0, 0, 0.06) !important;
  color: #4a4a4a !important;
}

.list-modal .nav-link {
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: #4a4a4a;
  border: none;
  border-radius: 0px;
  padding: 1rem;
}

.list-modal .nav-link p {
  margin: 0px;
}

.list-modal .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-modal .nav-link:active {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-modal .nav-link:focus {
  background-color: rgba(0, 0, 0, 0.06) !important;
  color: #4a4a4a !important;
}

/* List Module */

.list-module.card {
  padding: 0px;
}

.list-module.card .module-header {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.list-module.card .module-header h4 {
  margin: 0px;
}

.list-module .btn {
  width: 100%;
  text-align: left;
  background-color: transparent;
  color: #4a4a4a;
  border: none;
  border-radius: 0px;
  padding: 1rem;
}

.list-module .btn:hover {
  background-color: rgba(0, 0, 0, 0.06);
  color: #4a4a4a;
}

.list-module .btn:active {
  background-color: rgba(0, 0, 0, 0.06);
  color: #152340;
}

.list-module .btn:focus {
  background-color: rgba(0, 0, 0, 0.06) !important;
  color: #152340 !important;
}

/* General */

.underline {
  text-decoration: underline;
}

.custom-form input,
.form-control {
  border: none !important;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px !important;
}

.custom-form input,
.form-control::placeholder {
  color: #9a9a9a;
}

.checkout-page .custom-form input,
.form-control::placeholder {
  color: #D1D1D1;
}

.custom-table input.form-control.input-labelled {
  border: solid 2px #dee2e6 !important;
  /* border: solid 1px #4b4b4b !important; */
  border-radius: 10px 0px 0px 10px !important;
}

.subk-table input.form-control.input-labelled {
  border: solid 2px #dee2e6 !important;
  /* border: solid 1px #4b4b4b !important; */
  border-radius: 10px 0px 0px 10px !important;
}

.input-group > .input-group-append > .input-group-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

button.btn.delete-btn {
  border: 2px solid #ff635f;
  color: #ff635f;
  background-color: transparent;
}

/* New Project button */
.navbar-nav .nav-link.new-project-btn {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* Project Invoices list is_paid icon */
.icon-check.paid path {
  fill: #4a8950;
}

.icon-check.unpaid path {
  fill: #dadada;
}

/* New */

#register {
  background-color: #14337e;
  height: 100vh;
}

.register-page {
  width: 50% !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#register-process-status {
  color: white;
}

#register-step-contents {
  color: white;
}

#register-step-contents button.btn-alt {
  background-color: #152340;
  color: white;
  border: none;
}

#register-step-contents button.btn-main {
  border: solid 1px white;
  background-color: #152340;
  color: white;
}

#register-step-contents h2 {
  margin-bottom: 1rem;
}

#register-step-contents .row .card {
  padding: 0px;
}

#register-step-contents .row .card h4 {
  margin: 2rem;
}

#register-step-contents .row .card hr {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-top: 0px;
}

.register-card {
  border: none !important;
  border-radius: 8px !important;
  max-width: 830px !important;
  margin-bottom: 20px;
}

.register-card h5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.register-card .row {
  margin-left: 0px;
  margin-right: 0px;
}

.register-card input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.register-card input:active {
  border: none;
}

#register-summary {
  color: #152340;
}

#register-summary hr {
  margin-top: 0px !important;
}

#btn-logout {
  width: 100%;
}

#btn-upload-invoice {
  background-color: #4a8950;
  color: white;
  border-radius: 6px;
}

.badge.green-badge {
  /* background-color: #1c8526; */
  margin-right: 1rem;
  border-radius: 6px;
  padding: 0.5rem;
  background-color: #4a8950;
  min-width: 90px;
}

#page-upload-invoice-method .card {
  padding: 7rem;
}

#page-upload-invoice-method .svg-container svg {
  max-height: 70px;
  margin-bottom: 1.5rem;
}

.float-right  {
  float: right;
}

.float-left {
  float: left;
}

.add-company-member-modal .btn-dark {
  border-radius: 6px;
  max-width: 150px;
}

.hinzufügen-modal button {
  border-radius: 6px;
  max-width: 200px;
  padding: 10px 40px;
}

.hinzufügen-modal.change-address button {
  position: absolute;
  bottom: 0;
  right: 10px;
  border-radius: 8px;
  float: right;
  max-width: 200px;
  padding: 0.375rem 0.75rem;
}

.hinzufügen-option button {
  background-color: transparent;
  color: #152340;
  border: 2px solid transparent;
  width: 100%;
}

.hinzufügen-option button:hover {
  border: 2px solid transparent;
  background-color: transparent;
  color: #152340;
}

.hinzufügen-option .button-icon {
  float: none;
}

.button-icon {
  float: left;
  /* background-color: #152340; */
  padding: 0.25rem;
  /*
  border-radius: 8px;
  max-width: 2rem;
  max-height: 2rem;
  */
  display: flex;
  justify-content: center;
  /* margin-right: 1rem; */
}

.dashboard-aufgaben .card {
  width: 100%;
}

.dashboard-postfach .card {
  width: 100%;
}

button.btn-primary {
  border: 1px solid #14337e;
  background-color: #14337e;
  color: white;
  border-radius: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
}

button.btn-primary:hover {
  border: 1px solid #14337e;
  background-color: #14337e;
  color: white;
}

button.btn-white {
  border: 1px solid #C25982;
  background-color: white;
  color: #C25982;
  border-radius: 8px;
  padding-top: 7px;
  padding-bottom: 7px;
}

button.btn-white:hover {
  border: 1px solid #C25982;
  background-color: white;
  color: #C25982;
}

button.btn-white.active {
  border: 1px solid #C25982 !important;
  background-color: #C25982 !important;
  color: white !important;
}

button.btn-white.active:enabled {
  border: 1px solid #C25982 !important;
  background-color: #C25982 !important;
  color: white !important;
}

button.btn-transparent {
  padding-left: 0px;
  background-color: transparent;
  border: 1px solid transparent;
  color: #C25982;
}

button.btn-transparent svg path {
  fill: #C25982;
}

button.btn-transparent:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: #C25982;
}

.dashboard-projects .project-title {
  margin-bottom: 100px;
}

.projects form button {
  float: right;
  border-radius: 8px;
  max-width: 100px;
}

.projects form button:hover {
  background-color: #152340;
}

.table tr th {
  border-top: none;
  font-size: small;
  font-weight: 600;
}

.table tr td {
  font-size: small;
  font-weight: 600;
}

/* .project-list-labels.list-group-item {
  padding: 10px 0px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #e1e1e1;
} */

.projects .project-item td {
  padding-top: 22px;
  padding-bottom: 22px;
}

.projects .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.vergabeeinheit-list-item .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.vergabeeinheit .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.auftrag-list-item .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.nachtrag-list-item .badge {
  min-width: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
}

.projects .badge.incomplete {
  background-color: #979797;
}

.projects .badge.complete {
  background-color: #14337e;
}

.project-dashboard .invoices-table {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.project-dashboard .invoices-table p {
  margin-bottom: 2px;
}

.project-dashboard .progress-bars {
  margin-top: 15px;
}
.project-dashboard .progress {
  height: 30px;
  border-radius: 0px;
}

.project-dashboard .progress-bar {
  background-color: #14337e;
}

/*
.project-dashboard .progress-bars .bar-label-col {
  border-right: 1px solid #dadada;
} */

.project-dashboard .progress-bars .bar-col {
  padding-left: 0px;
}

.custom-table {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 0px;
}

/* .projects .status-col {
  margin-right: 75px;
}

.projects .badge.status-col {
  margin-right: 30px;
}

.projects .client-col {
  margin-right: 50px;
} */

.vergabeeinheiten .tab-button {
  max-width: 200px;
}

.budget .tab-button {
  max-width: 200px;
}

.project-dashboard .project-dashboard-notification {
  border-radius: 0px;
}

.project-dashboard .project-dashboard-notifications {
  margin-top: 15px;
}

.project-dashboard-notification p {
  margin-bottom: 0;
}

.project-dashboard-notification p.path {
  font-size: small;
  margin-bottom: 0.5rem;
}

.project-dashboard-notification a {
  color: #C25982;
  text-decoration: underline;
}

.project-dashboard-notification a:hover {
  color: #C25982;
}

.custom-table .table-footer td {
  border-top: 2px solid #dee2e6;
}

.add-kostengruppe .modal-body {
  padding: 0px;
}

form .hinzufügen-btn {
  border-radius: 6px;
  max-width: 200px;
  padding: 10px 40px;
}

.project-budget-field {
  /* border: 1px solid #E1E1E1;
  border-radius: 4px; */
  /* padding: 7px 5px; */
  margin: 0px;
}

button.btn-modal {
  border-radius: 8px;
  max-width: 200px;
  padding: 7px 40px;
}

.navbar-avatar {
  margin-top: -0.5rem;
  width: 50px !important;
  height: 50px !important;
}

.account-page-avatar {
  width: 120px !important;
  height: 120px !important;
}

.vergabeeinheit-list-item:hover {
  cursor: pointer;
}

.auftrag-list-item:hover {
  cursor: pointer;
}

.nachtrag-list-item:hover {
  cursor: pointer;
}

.rechnung-list-item:hover {
  cursor: pointer;
}

/* Account modal header */

.account-modal .modal-header .avatar {
  width: 50px !important;
  height: 50px !important;
}

.account-modal .modal-header p {
  margin: 0px;
}

.account-modal .modal-header p.role {
  font-weight: normal;
}

.settings-profile-tab .avatar {
  width: 120px !important;
  height: 120px !important;
  cursor: pointer;
}

.no-padding-right {
  padding-right: 0px;
}

.no-padding-left {
  padding-left: 0px;
}

.navbar-search {
  width: auto;
}

.navbar-search * {
  height: 40px;
}

.navbar-search input {
  border: white !important;
  width: 700px !important;
  background-color: white;
  border-radius: 10px 0px 0px 10px !important;
}

.navbar-search input::placeholder {
  color: #4b4b4b85;
  font-size: 15px;
}

.navbar-search button {
  background-color: white;
  border: white;
  color: #4a4a4a;
  font-weight: 700;
  border-radius: 0px 10px 10px 0px;
}

.navbar-search button:hover {
  background-color: white;
  border: white;
  color: #C25982;
  font-weight: 700;
}

.badge-secondary {
  background-color: #717171;
}

#not_found .container{
  margin-top: 3rem;
}

.filter-btn {
  border: 1px solid #C25982;
  border-radius: 8px;
}

.filter-btn:hover {
  border: 1px solid #C25982;
}

.filter-btn:active {
  border: 1px solid #C25982;
}

.market-list-item {
  color: #4a4a4a;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.market-list-item:hover {
  color: #4a4a4a;
  text-decoration: none;
}

.market-list-item .badge {
  padding: 0.5rem 0.6rem 0.5rem 0.6rem;
  background-color: #717171;
}

.items-tab {
  padding: 0px;
}

.items-tab .card-deck .card {
  padding: 0px;
  cursor: pointer;
}

.items-tab .card-deck .card-body {
  padding: 0rem 1rem 1rem 1rem;
}

.items-tab .card-title {
  font-weight: 800;
  font-size: ;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.items-tab .card-deck .card-subtitle {
  margin-bottom: 2rem;
}

.items-tab .card-deck .card-subtitle {
  color: #979797;
}

.items-tab .card-deck .card-text {
  font-weight: 800;
}

.market-page {
  width: 100%;
}

.market-page-cart {
  padding-top: 0.9rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  min-height: 700px;
}

.subtotal-price {
  text-align: right;
  font-weight: 800;
  font-size: 125%;
  color: #A9CB59;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 4px;
  padding: 0.5rem 1.5rem;
  margin-top: 0.5rem;
}

.market-page-cart .list-group {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  padding-top: 1rem;
}

.market-page-cart .btn {
  border: 1px solid #C25982;
  width: 100%;
}

.market-page-cart .list-group-item {
  padding: 10px;
}

.market-page-cart img {
  max-width: 50%;
}

.market-page-cart .list-group-item .price {
  margin-left: 0.5rem;
  font-size: 90%;
}

.same-day-order-banner {
  float: right;
}

.dashboard .feed img {
  max-width: 75%;
}

.card-img-overlay .text {
  position: absolute;
  bottom: 0;
  color: white;
}

.card-img-overlay .text h5{
  color: white;
}

.card-img-overlay .text p{
  font-weight: 400;
}

.card-img-overlay .text p.date{
  font-weight: 400;
  font-size: 90%;
}

.dashboard .dashboard-feed-item-text b {
  font-weight: 800;
}

.single-order-page img {
  max-width: 50%;
}

.orders-list-item {
  cursor: pointer;
}

.single-order-page svg, .single-market-list-page svg {
  height: 30px;
  color: #C25982;
  cursor: pointer;
}

.supplier-list-item {
  cursor: pointer;
}

.single-supplier-page svg.back-arrow {
  height: 30px;
  color: #C25982;
  cursor: pointer;
}

.back-button {
  height: 30px;
  color: #C25982;
  cursor: pointer;
}

.single-order-list-item {
  /* cursor: pointer; */
}

.single-item-page .item-image {
  padding: 30px;
  max-width: 100%;
}

.single-item-supplier-list-item .supplier-logo {
  max-width: 10%;
}

.add-item-to-cart-modal img {
  width: 100%;
  border-radius: 10px;
}

.add-item-to-cart-modal .item-icon-container {
  width: 100%;
}

.add-item-to-cart-modal .item-price {
  color: #A9CB59;
  font-size: 150%;
  font-weight: 700;
}

.item-supplier-list-item {
  color: #4a4a4a;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.checkout-page .list-group img {
  max-width: 100%;
}

.checkout-page .price {
  color: #C25982;
  font-size: 100%;
  font-weight: 700;
}

.checkout-page .price {
  margin-top: 25px;
}

.checkout-page h5 .price {
  margin-top: 0px;
}

.checkout-page .btn {
  border: 1px solid #C25982;
}

.checkout-page .btn.disabled {
  border: 1px solid #D1D1D1;
}

.checkout-page .btn.disabled:hover {
  background-color: #D1D1D1;
}

.checkout-page .disclaimer {
  font-size: 75%;
}

.checkout-card .item .item-name {
  font-weight: bold;
}

.checkout-card .item .item-farmer{
  font-size: 90%;
}

.checkout-card .item .item-price{
  color: #A9CB59;
  font-weight: bold;
}

.checkout-card .item .item-unit {
  color: #A9CB59;
  font-size: 90%;
}

.add-item-modal .card {
  cursor: pointer;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.add-item-modal img {
  max-width: 100%;
  padding: 15px 15px 0px 15px;
}

.add-item-modal .card-title {
  font-weight: 800;
  font-size: 120%;
  margin-bottom: 0.5rem;
}

.hinzufügen-modal button.btn-list-item {
  max-width: 100%;
  border-radius: 8px;
  text-align: left;
  padding: 0.75rem 1.25rem;
}

.hinzufügen-modal button.btn-list-item .button-icon {
  margin-right: 1.5rem;
}

.item-list-row {
  margin: 0;
  width: 100%;
}

.item-list-row .card{
  width: 32%;
  border-radius: 9px;
  padding: 0.5rem;
}

.item-list-row .card .card-body {
  padding-top: 0.25rem;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.item-list-row .card .card-body .itemCardPrice {
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 0px;
}

.item-list-row .card .card-body .itemCardName {
  font-size: 12px;
  font-weight: normal;
  color: black;
  margin-bottom: 0px;
}

.item-list-row .card .card-body .itemCardRegion {
  font-size: 8px;
  font-weight: normal;
  color: black;
  margin-bottom: 0px;
}

#addItemToCartButton {
  background-color: white;
  border: 1px solid #A9CB59;
  color: #A9CB59;
  border-radius: 8px;
  font-size: 30px;
  width: 100%;
  height: 50px;
  padding: 0px;
  float: right;
  margin-top: 2rem;
}

#addItemToCartButton svg{
  width: 15px;
  height: 15px;
  margin-top: -5px;
}

#addItemToCartButton svg path{
  fill: #A9CB59;
}

.item-list-item .card {
  margin-left: 0;
  margin-right: 0;
}

.add-item-to-cart-modal .market-list-item.list-group-item {
  border: 1px solid transparent;
  border-radius: 8px;
}

.add-item-to-cart-modal .market-list-item.list-group-item:hover {
  cursor: pointer;
  border: 1px solid #C2598250;
  background-color: white;
  color: #4a4a4a;
}

.add-item-to-cart-modal .market-list-item.list-group-item.active {
  border: 1px solid #C25982;
  background-color: white;
  color: #4a4a4a;
}

.item-farmer .row svg {
  position: absolute;
  top: 15px;
}

.item-farmer .row svg path{
  fill: #C25982;
}

button.btn-checkout-market-list {
  width: 100%;
  border: 1px solid #C25982;
  background-color: #C25982;
}

.change-address-modal a {
  color: #C25982;
}

.list-group-item.delivery-address-option {
  border: 1px solid transparent;
  border-radius: 8px;
}

.list-group-item.delivery-address-option:hover {
  cursor: pointer;
  border: 1px solid #C2598250;
  background-color: white;
  color: #4a4a4a;
}

.list-group-item.delivery-address-option.active {
  border: 1px solid #C25982;
  background-color: white;
  color: #4a4a4a;
}

.remove-cart-item-btn {
  cursor: pointer;
}

.download-invoice-btn {
  border: 1px solid #C25982;
}

.download-invoice-btn:hover {
  border: 1px solid #C25982;
}

.download-invoice-btn a {
  color: white;
}

.download-invoice-btn a:hover {
  text-decoration: none;
}

.market-list-add-items .card img {
  max-height: 5rem;
}

.item-request-list-item {
  color: #4a4a4a;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.item-request-list-item:hover {
  color: #4a4a4a;
  text-decoration: none;
}

.item-request-list-item .badge {
  padding: 0.5rem 0.6rem 0.5rem 0.6rem;
  background-color: #717171;
}

.market-page .market-list-item .btn {
  margin-left: 2rem;
  display: none;
  width: 20%;
}

.market-page .market-list-item:hover .btn {
  display: inline-block;
}

.market-page .market-list-item:hover .price {
  display: none;
}

.items-filter-row .col {
  display: flex;
  flex-direction: row;
}

.items-filter-row .col button.dropdown-toggle {
  background-color: transparent !important;
  border: none;
  color: #979797;
  margin-bottom: 1rem;
  outline: none;
  box-shadow: none;
}

.items-filter-row .col button.dropdown-toggle:focus {
  background-color: transparent !important;
  border: none;
  color: #979797 !important;
  outline: none !important;
  box-shadow: none !important;
}

.items-filter-row .col button.dropdown-toggle:active {
  background-color: transparent !important;
  border: none;
  color: #979797 !important;
  outline: none !important;
  box-shadow: none !important;
}

.items-filter-row .col .dropdown-menu {
  /* border: none; */
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.items-filter-row .col button.dropdown-item {
  background-color: transparent !important;
  color: #979797;
}

.analytics-page .btn {
  /* border: 1px solid #C25982; */
  outline: none;
  box-shadow: none;
  margin-right: 0.5rem;
}

.analytics-page .btn:focus {
  border: 1px solid #C25982 !important;
  background-color: white !important;
  color: #C25982 !important;
  outline: none !important;
  box-shadow: none !important;
}

.analytics-page .btn:active {
  border: 1px solid #C25982 !important;
  background-color: white !important;
  color: #C25982 !important;
  outline: none !important;
  box-shadow: none !important;
}

.analytics-page input {
  width: 35%;
  display: inline-flex;
}

.upload-profile-picture-modal .btn {
  border: 1px solid #C25982;
}

.upload-profile-picture-modal .displayed-image {
  max-height: 150px;
  max-width: 150px;
  margin-bottom: 1rem;
}

.upload-profile-picture-modal .left-side {
  padding-right: 5px;
}

.upload-profile-picture-modal svg{
  width: 100%;
}

.upload-profile-picture-modal .right-side {
  padding-left: 5px;
}

.upload-profile-picture-modal form{
  padding: 0px;
}

.upload-profile-picture-modal form .form-group{
  margin-bottom: 0px;
}

.upload-profile-picture-modal p{
  margin-top: 0.5rem;
  font-size: 14px;
  margin-bottom: 0px;
}

.upload-profile-picture-modal button.upload-picture-btn{
  padding: .75rem .75rem;
  color: #C25982;
  background-color: white;
  border: 1px solid white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  font-weight: 700;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  font-weight: 700;
  width: 100%;
  color: #A9CB59;
  background-color: white;
  display: inline-block;
  border: 1px solid white;
  border-radius: 8px;
  padding: .75rem .75rem;
}

.inputfile:focus + label,
.inputfile + label:hover {
  cursor: pointer;
}

.edit-text-btn path {
  fill: #C25982;
}

.marketlist-name b:hover {
  color: #C25982;
  cursor: pointer;
}

.list-group-item.payment-method-option {
  border: 1px solid transparent;
  border-radius: 8px;
}

.list-group-item.payment-method-option:hover {
  cursor: pointer;
  border: 1px solid #C2598250;
  background-color: white;
  color: #4a4a4a;
}

.list-group-item.payment-method-option.active {
  border: 1px solid #C25982;
  background-color: white;
  color: #4a4a4a;
}

.hinzufügen-modal.change-payment-method button {
  border-radius: 8px;
  float: right;
  max-width: 200px;
  padding: 0.375rem 0.75rem;
}

.card-element {
  border: solid 2px #dee2e6;
  border-radius: 10px;
  padding: .45rem .75rem;

}

.notifications-modal-supplier-avatar {
  max-width: 100%;
}

.notifications-modal {
  border-right: 2px solid #DDDDDD;
  padding-right: 0.5rem;
}

.light-grey {
  color: #DDDDDD;
}

.dark-grey {
  color: #909090;
}

#register-page a {
  color: #4f4f4f;
}

#register-page a:hover {
  color: #4f4f4f;
}

.dashboard-news .card-body {
  padding: 0px 0px 5px 0px;
}

button.delivery-date-btn {
  background-color: white;
  border: 1px solid #C25982;
  border-radius: 8px;
  color: #C25982;
  width: 100%;
}

button.delivery-date-btn:hover {
  background-color: white;
  color: #C25982;
}

button.delivery-date-btn:active {
  border: 1px solid #C25982 !important;
  background-color: #C25982 !important;
  color: white !important;
}

button.delivery-date-btn.active {
  border: 1px solid #C25982 !important;
  background-color: #C25982 !important;
  color: white !important;
}

.footer-link:hover {
  color: #979797;
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  color:#979797;
}

button.user-type-btn {
  background-color: white;
  border-color: #C25982;
  color: #C25982;
  height: 48px;
  font-size: 14px;
}

button.user-type-btn:hover {
  background-color: #C2598220;
  border-color: #C25982;
  color: #C25982;
}

button.user-type-btn.active {
  background-color: #C25982 !important;
  border-color: #C25982 !important;
  color: white !important;
}

.market-page .nav-item a.nav-link {
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0.5rem 3.5rem;
  margin-right: 1.5rem;
}

.settings-page .nav-item a.nav-link {
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0.5rem 3.5rem;
  margin-right: 1.5rem;
}

.items-tab div.item-price {
  font-weight: 400;
}

.items-tab div.item-price span {
  font-weight: bold;
  font-size: 22px;
}

.modal-body div.item-price {
  font-weight: 400;
  font-size: 100%;
}

.modal-body div.item-price span{
  font-weight: bold;
}

.checkout-card {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.checkout-card .header {
  background-color: #A9CB59;
  border-radius: 8px 8px 0px 0px;
}

.checkout-card .header h5 {
  font-size: 16px;
  font-weight: 700;
  color: white;
  margin: 0.7rem 0.2rem
}

.checkout-card .body {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  padding-left: 0.2rem;
}

.checkout-card .body .form-group {
  margin-bottom: 0px;
}

.checkout-card .item.list-group-item img {
  border-radius: 6px;
}

.user-region-nav-item svg {
  width: 85%;
}

.notifications-nav-item svg {
  width: 90%;
}

.checkout-card hr {
  border-top: 1px solid #A9CB59;
}

.express-shipping-svg {
  height: 50px;
}

.item-icon-container {
  width: 100%; /* width of container */
  height: 225px; /* height of container */
  overflow: hidden;
  padding: 0.8rem;
}

.items-tab img{
  border-radius: 6px;
}

.cart-modal .item-icon-col img{
  border-radius: 10px;
}

/* .market-page-cart .item-icon-container {
  width: 20px;
} */

.cart-icon {
  max-width: 80%;
}

.cart-modal .modal-content{
  background-color: #F6F6F6;
  border: none;
}

.cart-modal .modal-content .btn {
  width: 100%;
}

.cart-modal .item-icon-container img{
  width: 100%;
  padding: 0.4rem;
}

.cart-modal .item-name {
  font-weight: bold;
  margin-bottom: 0px;
}

.cart-modal .item-farm {
  margin-bottom: 0px;
}

.cart-modal .item-per-unit-price {
  margin-bottom: 0px;
  color: #A9CB59;
}

.cart-modal .item-price {
  font-size: 14px;
  margin-top: 1.8rem;
  font-weight: bold;
  color: #C25982;
}

.cart-modal label {
  /* margin-top: 0.5rem; */
}

.add-item-to-cart-modal button {
  max-width: none;
}

.checkout-left-block {
  margin-right: 1rem;
  padding-left: 30px;
}

.checkout-right-block {
  padding-right: 30px;
}

.add-market-list-modal button{
  max-width: none;
}

.sidebar .navbar-brand {
  max-width: 100%;
}

.market-list-add-items .item-list-item .card-body{
  padding: 0px;
}

.market-list-add-items .item-list-item .item-icon-container{
  margin-bottom: 0.5rem;
}

.dashboard-feed .feed-item {
  text-decoration: none;
}

.news-page-related-items a:hover {
  text-decoration: none;
}

.order-success-page {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.order-success-page .btn {
  width: 40%;
}

.order-status-yellow {
  color: #F4B71A;
}

.order-status-red {
  color: #C25982;
}

.order-status-green {
  color: #A9CB59;
}

.orders-tab {
  margin-top: 3rem;
}

.orders-tab .card {
  padding: 0px;
}

.orders-tab h5 {
  background-color: #A9CB59;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 0px;
}

.orders-tab thead {
  background-color: #EDEDED;
}

.orders-tab thead th{
  border-bottom: none;
}

.orders-tab .table td, .table th{
  border-top: none;
}

.single-order-card {
  padding: 0px !important;
}

.single-order-card h5 {
  background-color: #C25982;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 0px;
}

.single-order-card thead {
  background-color: #EDEDED;
}

.single-order-card thead th{
  border-bottom: none;
}

.single-order-card .table td, .table th{
  border-top: none;
}

.react-datepicker-wrapper input{
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  text-align: center;
}

.checkout-card textarea#delivery_instructions {
  border: none !important;
  background: #F3F3F3;
}

#paypal-gateway-div.disabled {
  display: none;
}

#register-success-page {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#register-success-page .btn {
  width: 40%;
}

#single-farmer-page img {
  max-width: 100%;
  border-radius: 8px;
}

.farmer-certification {
  margin-right: 0.5rem;
}

#single-farmer-page img.farmer-gallery-item {
  max-width: 29%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0px !important;
}

.farmer-item-list {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.farmer-item-list button{
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  background-color: #A9CB59;
  border-color: #A9CB59;
  color: white;
  font-weight: 600;
}

.farmer-item-list button:hover{
  background-color: #A9CB59;
  border-color: #A9CB59;
}

.farmers-list-item img {
  width: 100%;
  border-radius: 8px;
}

.farmer-data p {
  margin-bottom: 0.5rem;
}

#farmers-page .form-group {
  margin-bottom: 0px;
}

.form-check-field {
  text-align: left;
}

.add-item-to-cart-modal .modal-header {
  background-color: #A9CB59;
  border-bottom: none;
}

.add-item-to-cart-modal .modal-body {
  padding: 1.5rem;
}

.add-item-to-cart-modal .modal-header h5{
  color: white;
}

.add-item-to-cart-modal .modal-header .close{
  color: white;
  opacity: 1;
}

.add-item-to-cart-modal .modal-header .close:hover{
  opacity: 1;
}

.market-list-item .badge {
  background: none;
  color:#C25982;
  font-size: 1rem;
  border: 1px solid #C25982;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.contact-form .header {
  background-color: #A9CB59;
  border-radius: 8px 8px 0px 0px;
}

.contact-form .header h5{
  color: white;
  margin: 0.7rem 0.2rem
}

.contact-form .body {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  /* border-radius: 0px 0px 8px 8px; */
  border-radius: 8px;
  padding: 20px 0px 10px 0px;
}

.contact-form button {
  border-radius: 8px;
  padding: 0.6rem 0rem;
}

/* .farmers-list-item {
  width: 50%;
    padding: 0px;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
} */

/* #farmers-page input{
  width: 50% !important;
} */

#refer-a-farmer-btn {
  width: 100%;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.farmer-list-row {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.farmer-list-item .card {
  padding: 0px;
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.farmer-list-item a:hover {
  text-decoration: none;
}

.farmer-list-item b{
  color: #4f4f4f;
}

.farmer-list-item:hover b{
  color: #4f4f4f;
}

.farmer-list-item .card .card-body{
  padding: 0px;
}

.farmer-list-item .card img {
  max-width: 100%;
  width: 130px;
  border-radius: 50%;
  border: 7px solid #C25982
}

.farmer-card-title {
  background-color: #C25982;
  color: white;
  text-align: center;
  padding: 0.5rem 0rem;
}

.modal-header {
  background-color: #A9CB59;
  border-bottom: none;
  padding: 0.5rem 1rem;
}

.modal-header h5{
  color: white;
  font-size: 16px;
}

.modal-header .close{
  color: white;
  opacity: 1;
  margin: -1.2rem -1rem -1rem auto
}

.modal-header .close:hover{
  opacity: 1 !important;
}

button.notification {
  background-color: white;
  color: #4f4f4f;
  border: none;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 12.5px
}

button.notification:hover {
  background-color: white;
  color: #4f4f4f;
}

button.notification:focus {
  background-color: white;
  color: #4f4f4f;
}

button.notification .notification-text{
  text-align: left;
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  color: #5F5F5F
}

#notifications-modal .modal-body {
  background-color: #CACACA;
  padding: 0.5rem
}

.green-header-container {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border-radius: 0px 0px 8px 8px;
}

.green-header {
  background-color: #A9CB59;
  border-radius: 8px 8px 0px 0px;
}

.green-header h5{
  color: white;
  margin: 0.7rem 0.2rem
}

.green-header-body {
  padding-top: 20px;
  padding-bottom: 20px;
}

.green-header-body .custom-btn {
  max-width: none;
}

.btn-dark:disabled {
  background-color: #D1D1D1;
  border-color: #D1D1D1;
}

.btn-dark:disabled:hover {
  background-color: #D1D1D1 !important;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #D1D1D1;
}

.settings-page .MuiAvatar-root {
  width: 125px;
  height: 125px;
  margin-left: auto;
  margin-right: auto;
}

.single-market-list-page h5 {
  font-weight: 700;
}

.single-market-list-page .card {
  padding: 0px;
}

.single-market-list-page h5 {
  padding: 1rem 1.5rem
}

.single-market-list-page button {
  /* margin: 0px 1.5rem 1rem 1.5rem; */
  max-width: 200px;
  padding: 10px 10px;
}

.single-market-list-page thead {
  background-color: #EDEDED;
}

.single-market-list-page .table th,.table td {
  border-top: none;
  border-bottom: none;
}

.cart-modal img {
  width: 100%;
}

.contact-form textarea.form-control {
  height: 120px;
}

.dashboard-feed img {
  width: 100%;
}

.dashboard-feed .feed-item .feed-item-text-col {
  padding-left: 0px;
}

.delete-marketlist-btn {
  margin-top: 0.5rem;
}

.delete-itemrequest-btn {
  margin-top: 0.25rem;
}

.add-item-to-cart-modal .item-info-col h5.item-price{
  font-size: 16px;
}

.add-item-to-cart-modal .item-info-col h5.item-price span{
  font-size: 125%;
}

.news-page-related-items .feed-item h5 {
  font-size: 16px;
}

.news-page-related-items .feed-item p {
  font-size: 14px;
  margin-bottom: 0px;
}

button.btn-upload-pp-modal {
  max-width: none;
}

.form-input {
  position: relative;
  display: flex;
  width: 100%;
}

.form-input input {
  padding: 0.5rem 0.75rem 0.5rem 2.75rem;
  height: 48px;
}

.input-icon {
  width: 15px;
  position: absolute;
  left: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-market-list-page h5 {
  margin-bottom: 0px;
}

.single-market-list-page button {
  margin-bottom: 20px !important;
}

.market-list-add-items .item-list-item.col-sm-3 {
  flex: 0 0 33%;
  max-width: 33%;
}

.market-list-add-items .card {
  padding: 0.75rem;
}

.market-list-add-items .item-icon-container {
  height: 100%;
  padding: 0px;
}

.market-list-add-items .item-list-item img {
  padding: 0px;
  max-height: 175px;
  border-radius: 10px;
}

.market-list-add-items .item-list-item .card-title {
  font-size: 18px;
}

.market-list-add-items .item-list-item .card-subtitle {
  font-size: 12px;
}

.market-list-add-items .item-list-item .card-text {
  margin-top: 2rem;
}

.market-list-add-items .item-list-item .card-text .item-price{
  font-size: 14px;
}

.market-list-add-items .item-list-item .card-text .item-price span{
  font-size: 24px;
}

.farmer-articles p.dark-grey {
  margin-bottom: 0.8rem;
}

.farmer-articles a {
  margin-right: 1rem;
  padding: .375rem .75rem;
  border: 2px solid #A9CB59;
  border-radius: 8px;
}

.farmer-articles a:hover {
  color: #979797;
  text-decoration: none;
}

.add-item-to-cart-modal .item-price br{
  display: none;
}

.add-item-to-cart-modal .item-farmer.hide-on-mobile {
  /* margin-top: 45px; */
}

.item-quantity-selector {
  position: relative;
}

.cart-modal .modal-content .item-quantity-selector button {
  width: 25px;
}

.checkout-page .item-quantity-selector button {
  border-color: white;
}

.add-item-to-cart-modal .item-quantity-selector button {
  width: 25px;
}

.item-quantity-selector button {
  position: absolute;
  width: 25px;
  height: 25px;
  padding: 0px;
  background-color: white;
  border-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.item-quantity-selector button:focus {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.item-quantity-selector button:active {
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}

.item-quantity-selector button svg{
  margin-bottom: 2.5px;
}

.item-quantity-selector button:hover {
  background-color: white;
  border-color: white;
}

.item-quantity-selector p {
  position: absolute;
  right: 50%;
}

.item-quantity-selector button.plus-btn {
  right: 0;
}

.table tbody+tbody {
  border-top: none;
}

.single-order-card .order-status {
  background-color: white;
  padding: 5px 20px;
  border-radius: 4px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  font-size: 16px;
}

.headliner img {
  border-radius: 8px;
}

#register-page .login-page {
  top: 60%;
}

#register-page.login {
  min-height: 150vh;
  background-color: #f5f6fa;
}

.register-checkbox {
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}

.register-checkbox.form-check-inline .form-check-input {
  margin-right: 0.75rem;
}

.subscribe-to-membership-modal form {
  padding-left: 0px;
  padding-right: 0px;
}

.subscribe-to-membership-modal h5 {
  text-align: left;
}

.subscribe-to-membership-modal .membership-item {
  padding-top: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.subscribe-to-membership-modal .membership-item svg{
  font-size: 20px;
}

.subscribe-to-membership-modal .membership-item .price{
  color: #C25982;
  font-size: 100%;
  font-weight: 700;
}

.subscribe-to-membership-modal .change-address button {
  margin-bottom: 4px;
}

.card .farm-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.dashboard-feed .feed-item-col {
  min-width: 50%;
}

.potential-discount-notice {
  color: #C25982;
  cursor: pointer;
}

.hellofarmer-premium-link {
  text-decoration: underline;
}

.hellofarmer-premium-link:hover {
  color: #C25982;
  cursor: pointer;
}

.already-a-member-modal svg{
  width: 50%;
  height: 50%;
}

._loading_overlay_wrapper ._loading_overlay_overlay {
  background: rgba(0, 0, 0, 0.5);
}

.premium-icon.mobile-nav-item img{
  max-width: 65%;
}

.nav-item #premium-icon-long {
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 8%;
}

.nav-item #premium-icon-short {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 25%;
}

#notifications-modal ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv{
  margin-left: auto;
  margin-top: 45vh;
}

.items-tab ._loading_overlay_wrapper ._loading_overlay_overlay{
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.items-tab ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv{
  margin-left: auto;
  margin-top: 30vh;
}

.checkout-card.delivery-address .active-delivery-address {
  border: 2px solid #C25982;
  border-radius: 8px;
  max-width: 85%;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.items-tab .item-list-item .card.active {
  border: 2px solid #C25982;
}

#admin-navbar-link {
  padding-left: 0px;
  font-weight: bold;
  color: #C25982;
}

.admin-tab .add-btn {
  background-color: white;
  color: #A9CB59;
  padding: 5px 20px;
  border-radius: 4px;
  position: absolute;
  right: 40px;
  bottom: 14px;
  font-size: 16px;
  cursor: pointer;
}

.admin-tab .add-btn a {
  color: #A9CB59;
}

.admin-tab .add-btn a:hover {
  color: #A9CB59;
  text-decoration: none;
}

.mobile-nav-item svg circle {
  fill: #C25982
}

.cart-nav-item svg circle {
  fill: #C25982
}

#farmers-page ._loading_overlay_wrapper ._loading_overlay_overlay{
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

#farmers-page ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv{
  margin-left: auto;
  margin-top: 25vh;
}

#single-farmer-page ._loading_overlay_wrapper ._loading_overlay_overlay{
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

#single-farmer-page ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv{
  margin-left: auto;
  margin-top: 25vh;
}

#orders-page ._loading_overlay_wrapper ._loading_overlay_overlay{
  background-color: #f5f6fa;
  /* background-color: rgba(0, 0, 0, 0.25); */
}

#orders-page ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv{
  margin-left: auto;
  margin-top: 25vh;
  color: #9A9A9A
}

#orders-page ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv circle{
  stroke: #9A9A9A
}

.dashboard ._loading_overlay_wrapper ._loading_overlay_overlay{
  background-color: #f5f6fa;
  /* background-color: rgba(0, 0, 0, 0.25); */
}

.dashboard ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv{
  margin-left: auto;
  margin-top: 25vh;
  color: #9A9A9A
}

.dashboard ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv circle{
  stroke: #9A9A9A
}

.modal .modal-content {
  border-radius: 8px;
  /* background-color: rgba(0,0,0,0); */
  border: none
}

.modal .modal-header {
  border-radius: 8px 8px 0px 0px;
}

.page {
  padding-top: 100px;
}

#admin-create-farmer-modal .inputfile + label{
  color: white;
  background: #C25982;
  font-weight: normal;
  box-shadow: none;
  padding: 0.5rem 0.75rem;
  text-align: center;
}

#admin-create-item-modal .inputfile + label{
  color: white;
  background: #C25982;
  font-weight: normal;
  box-shadow: none;
  padding: 0.5rem 0.75rem;
  text-align: center;
}

.nav-link.disabled {
  background-color: #D1D1D1;
  border-color: #D1D1D1;
}

.nav-link.disabled p,.nav-link.disabled svg{
  color: white !important;
}

#account-modal .nav-link.disabled {
  border-radius: 8px 8px 0px 0px;
}

.disabled {
  pointer-events: none;
  cursor: default;
}

#rename-modal {
  cursor: pointer;
  font-size: 18px;
  color: rgb(133, 133, 133);
  margin-bottom: 0.4rem;
  font-weight: 400;
}

#rename-modal svg{
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
}

#rename-modal:hover {
  color: #C25982 !important;
}

.delete-market-list-item:hover {
  cursor: pointer;
}

.sidebar-register {
  cursor: pointer;
}

.swal2-icon.swal2-warning {
  color: #A9CB59 !important;
  border-color: #A9CB59 !important;
}

#add-marketlist-to-cart-btn {
  max-width: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.admin-single-element-page ._loading_overlay_overlay{
  border-radius: 4px;
  background-color: #f5f6fa;
}

.admin-single-element-page ._loading_overlay_overlay .css-42igfv{
  color: #9A9A9A
}

.admin-single-element-page ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv circle{
  stroke: #9A9A9A
}

#admin-single-item-page #edit-item-button {
  float: right;
  margin-top: -6px;
  border: 2px solid white;
  margin-right: 0.5rem;
}

#admin-single-item-page #delete-item-button {
  float: right;
  margin-top: -6px;
  border: 2px solid white;
  background-color: white;
  color: #C25982;
}

#admin-single-item-page #edit-item-button.active {
  background-color: white;
  color: #C25982;
}

#admin-single-item-page p {
  margin-bottom: 0.25rem;
}

#admin-single-item-page p.value {
  color: #C25982;
  margin-bottom: 0.5rem;
}

#admin-single-item-page .edit-item-input-row {
  display: flex;
  flex-direction: row;
}

#admin-single-item-page .edit-item-input-row input, #admin-single-item-page .edit-item-input-row textarea {
  margin-right: 1rem;
}

#admin-page ._loading_overlay_overlay{
  border-radius: 4px;
  background-color: #f5f6fa;
}

#admin-page ._loading_overlay_overlay .css-42igfv{
  margin-left: auto;
  margin-top: 25vh;
  color: #9A9A9A
}

#admin-page ._loading_overlay_wrapper ._loading_overlay_overlay .css-42igfv circle{
  stroke: #9A9A9A
}

.toggle-item-nutritional-facts-btn {
  cursor: pointer;
  color: #C25982;
}

.farmer-item-quantity input {
  
}

.market-page .nav-item a.filter-navlink {
  padding: 0.5rem 1rem;
}

.market-page .items-tab .filter-btn {
  border: none;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

button.item-filter {
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  background-color: white !important;
  color: #979797 !important;
  border-color: white !important;
}

button.item-filter:hover {
  background-color: white !important;
  color: #C25982 !important;
  border-color: #C2598250 !important;
}

button.item-filter.active {
  background-color: white !important;
  color: #C25982 !important;
  border-color: #C25982 !important;
}

button.item-filter::after {
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  background-color: white;
  color: #979797;
  border-color: white;
}

button.item-filter .remove-item-filter-btn {
  float: right;
  display: none;
}

button.item-filter .remove-item-filter-btn.active {
  float: right;
  display: block;
}

.admin-create-item-nutritional-fact {
  padding-left: 0.75rem;
  margin-bottom: 0px;
}

.alert {
  border-radius: 10px;
  padding: 0.5rem 1.25rem;
}

.admin-page-nav-2 {
  margin-top: 1rem;
}

.mobile-navbar-item-label {
  display: none;
}

@media only screen and (max-width: 1234px){
  .sidebar-svg {
    display: none;
  }

  #authPage {
    background-image: none
  }
}

@media only screen and (min-width: 768px) {
  #order-list-item-mobile {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }

  .mobile-nav-item {
    display: none;
  }

  #help-page .container {
    max-width: 50%
  }

  #single-farmer-page-farmer-card {
    display: none;
  }

  .items-tab div.item-price br {
    display: none;
  }

  .add-item-modal div.item-price br {
    display: none;
  }

  .mobile-item-farmer {
    display: none;
  }

  .news-item-header {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    text-decoration: none !important;
    color: white !important;
  }
  
  .news-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    text-decoration: none !important;
    color: white !important;
  }

  .feed-item .news-item-text {
    -webkit-line-clamp: 3; /* number of lines to show */
    color: #979797 !important;
  }
  
  .headliners a:hover {
    text-decoration: none !important;
  }
  
  .headliners .headliner {
    position: relative;
  }
  
  .headliners .headliner .news-item-header{
    position: absolute;
    bottom: 65px;
    padding-left: 1rem;
    padding-right: 2rem;
  }
  
  .headliners .headliner .news-item-text{
    position: absolute;
    bottom: 5px;
    padding-left: 1rem;
    padding-right: 2rem;
  }
  
  #settings-page-save-button-col {
    padding-left: 0px;
  }

  #notifications-modal-close-button-row {
    display: none;
  }

  #settings-page-card #btn-logout .dark-grey {
    color: white
  }
  
  #market-items-col {
    padding: 0px;
    max-width: 85%;
    margin-top: 1.5rem;
    padding-right: 2.5%;
  }

  .item-list-row .card{
    width: 10%;
    border-radius: 9px;
    padding: 0.5rem;
    margin-right: 0.5rem;
  }

  #addItemToCartButton {
    background-color: white;
    border: 1px solid #A9CB59;
    color: #A9CB59;
    border-radius: 50px;
    font-size: 30px;
    width: 50px;
    height: 50px;
    padding: 0px;
    float: right;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 500px) {
  /* For mobile phones: */
  .hide-on-mobile {
    display: none !important;
  }

  #authPage .authButtonPrimary {
    height: 40;
    font-size: 14;
    font-weight: 500;
    width: 80%;  
    margin-bottom: 1rem;
  }
  
  #authPage .authButtonSecondary {
    height: 40;
    font-size: 14;
    font-weight: 500;
    width: 100%;
    background-color: #A9CB59;
    border-color: #A9CB59;
    margin-bottom: 1rem;
  }
  
  #authPage .authButtonAlt {
    height: 40;
    font-size: 14;
    font-weight: 500;
    font-size: 14;
    width: 80%;
    background-color: #9A9A9A42;
    border-color: #9A9A9A42;
  }
  
  #authPage #authOrContainer {
    margin-bottom: 1rem;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
  }

  .login-page {
    width: 100% !important;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  #authPage .login-page .authButtonPrimary, #authPage .login-page .authButtonAlt, #register-page .authButtonPrimary {
    width: 100%;
  }

  .login-page h2 {
    font-size: 1.5rem;
  }

  .login-page img {
    width: 65%;
  }

  .login-page button {
    width: 100%;
  }

  #register-page .login-page {
    top: 0px;
    left: 0px;
    transform: none;
    background-color: white;
  }

  #password-validation .col {
    flex-basis: auto;
  }

  #password-validation .col span{
    font-size: 80%;
  }

  #forgot-password-page p {
    font-size: 90%;
  }

  #forgot-password-page form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #forgot-password-page button {
    width: 100%;
  }

  #mobile-navbar-logo img{
    max-width: 108px;
  }

  #mobile-navbar-logo{
    padding: 0px;
  }

  .navbar-brand {
    display: none;
  }

  .feed.list-group img {
    display: none;
  }

  .feed.list-group .dashboard-feed-item-text {
    margin-bottom: 1rem;
  }

  .add-supplier-btn {
    padding: 3px !important;
  }

  .add-supplier-btn .button-icon {
    display: none;
  }

  .single-supplier-page h5 {
    text-align: center;
  }

  .single-supplier-page .supplier-img-col .MuiAvatar-root {
    margin: auto !important;
  }

  .notification-content {
    padding: 0rem 1rem;
  }

  .notification-content .MuiAvatar-root {
    display: none;
  }

  .notification-content .notification-text {
    margin-bottom: 1rem !important;
    font-size: 14px;
  }

  .notification-content .greyed-out {
    font-size: 12px;
  }

  .notification-content .col-sm-3 {
    width: 100%;
  }

  .account-modal.list-modal .col,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-9 {
    width: auto;
  }

  .settings-profile-tab button.custom-btn {
    max-width: none;
  }

  button.create-order-btn {
    padding: 5px;
  }

  .create-order-btn .button-icon {
    display: none;
  }

  button.change-delivery-address-btn {
    width: 100%;
  }

  .card.order-total-secondary {
    display: none;
  }

  .card.order-total-primary {
    margin-top: 1rem;
  }

  .notification-toggle-text {
    margin-bottom: 0.5rem;
  }

  .notification-toggle .overview-button{
    float: left;
  }

  .btn-save-user-field {
    width: 100%;
    margin-bottom: 1rem;
  }

  .settings-page-user-pp {
    margin-bottom: 1rem;
    position: fixed;
    left: 50%;
    margin-left: -20%;
  }

  .upload-profile-picture-modal .left-side {
    padding-left: 15px;
  }

  .upload-profile-picture-modal .right-side {
    padding-right: 15px;
  }

  .upload-profile-picture-modal .btn-upload-pp-modal {
    max-width: none;
    margin-top: 1rem !important;
  }

  .dashboard-news img {
    width: 100%;
  }

  .dashboard-feed .feed-item-col {
    flex-basis: auto;
  }

  .dashboard-feed .feed-item h5 {
    font-size: 16px;
  }

  .dashboard-feed .feed-item p {
    display: none
  }

  .dashboard-feed img {
    max-height: 225px;
    margin-bottom: 0.5rem;
  }

  .dashboard-subtitle {
    color: #9E9E9E;
  }

  .sidebar,.toggle-sidebar-btn {
    display: none;
  }

  .navbar-nav .express-shipping-svg{
    display: none;
  }

  .notifications-modal {
    margin-left: 0px !important;
  }

  .navbar-avatar {
    margin-left: 0px;
  }

  .mobile-nav-item path {
    fill: #909090;
  }

  .mobile-nav-item.dashboard-icon{
    margin-top: 10%;
  }

  .mobile-nav-item .nav-link {
    padding: 0.5rem 0px;
    margin-right: 0.75rem;
  }

  .collapse .cart-nav-item, .collapsing .cart-nav-item {
    display: none;
  }

  .collapse .user-region-nav-item, .collapsing .user-region-nav-item {
    display: none;
  }

  .collapse .notifications-nav-item, .collapsing .notifications-nav-item {
    display: none;
  }

  .collapse .user-account-nav-item, .collapsing .user-account-nav-item {
    display: none;
  }

  .collapse .fa-cog, .collapsing .fa-cog {
    margin-left: 2px;
  }

  .collapse .mobile-nav-item, .collapsing .mobile-nav-item {
    padding: 5% 0px 5% 0px;
  }

  .collapse, .collapsing {
    font-size: 20px;
  }

  .collapse button, .collapsing button{
    font-size: 20px;
  }

  #btn-logout {
    background-color: white;
    text-align: left;
    border: none;
    padding: 0px 0px 0px 3px;
    margin-top: 20%;
  }

  .market-page .nav-item {
    text-align: center;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .market-page .nav-item .nav-link{
    width: 100%;
  }

  .item-list-row .item-list-item {
    width: 50%;
    padding: 0px;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }

  .checkout-card {
    margin: 1rem 0rem 1rem 0rem;
  }

  .checkout-left-block {
    margin-right: 0px;
    padding-left: 15px;
  }

  .checkout-right-block {
    padding-right: 15px;
  }

  .change-delivery-address-btn {
    margin-top: 50%;
    max-width: none !important;
    width: 100%;
  }

  .add-item-to-cart-modal .item-icon-container {
    height: 100%;
    text-align: center;
  }

  .add-item-to-cart-modal .item-icon-container img{
    width: 50%;
  }

  .add-item-to-cart-modal .item-name {
    margin-top: 1rem;
  }

  .add-item-to-cart-modal .item-quantity p{
    margin-bottom: 0px;
  }

  .add-item-to-cart-modal .item-farmer {
    margin-top: 1rem;
  }

  .add-item-to-cart-modal .btn {
    max-width: none;
    width: 100%;
  }

  .add-item-to-cart-modal .cancel-btn {
    margin-top: 1rem !important;
  }

  .cart-modal .list-group-item .item-icon-col {
    width: 30%;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 0.5rem;
  }

  .cart-modal .list-group-item .item-info-col {
    padding-left: 5px;
  }

  .cart-modal .list-group-item .item-icon-col img{
    border-radius: 8px;
  }

  .cart-modal .list-group-item .item-per-unit-price {
    font-size: 12px;
  }

  .cart-modal .list-group-item .item-farm {
    font-size: 12px;
  }

  .cart-modal .list-group-item .item-quantity label{
    font-size: 10px;
  }

  .cart-modal .list-group-item .item-quantity select{
    font-size: 10px;
    max-width: 60%;
  }

  .cart-modal .list-group-item .item-price{
    font-size: 14px;
  }

  .orders-tab .create-order-link {
    display: none;
  }

  .sidebar {
    width: 5% !important;
  }

  .main-content {
    width: 100% !important;
  }

  .add-item-modal button {
    max-width: none;
  }

  .add-item-modal .cancel-btn {
    margin-top: 1rem !important;
  }

  .dashboard-news img {
    margin-bottom: 1rem;
  }

  .farmer-list-row .farmer-list-item {
    width: 50%;
    padding: 0px;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }

  .modal-body form {
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-full-width-col {
    flex-basis: auto;
  }

  .single-market-list-page h5 {
    padding: 0px;
    font-size: 20px !important;
  }

  .contact-form {
    margin-left: 0px;
    margin-right: 0px;
  }

  #farmers-page input {
    margin-top: 1rem;
    width: 100% !important;
  }

  #refer-a-farmer-btn {
    margin-top: 1rem;
    padding-left: 0px;
    padding-right: 0px;
  }

  #single-farmer-page .farmer-map {
    margin-top: 1.5rem;
  }

  .farmer-list-item .card img {
    width: 90%;
  }

  .farmer-list-item .card p:not(.farmer-card-title) {
    font-size: 12px;
  }

  .market-page .nav {
    flex-wrap: nowrap;
  }

  .market-page .nav-item .nav-link {
    font-size: 14px;
    padding: 10px 0px!important;
    
  }

  .market-page .nav-item.mobile-right-margin {
    margin-right: 0.5rem;
  }

  .market-page .nav-item.mobile-left-margin {
    margin-left: 0.5rem;
  }

  .dashboard-subtitle {
    display: none;
  }

  .notification-content .col-sm-1 {
    width: auto;
  }

  #mobile-nav-farmers-label {
    padding-left: 11px;
  }

  #single-farmer-page-farmer-card {
    padding: 0px;
    border-radius: 16px;
    margin-bottom: 1rem;
  }

  #single-farmer-page-farmer-card img.Card{
    width: 130px;
    border-radius: 50%;
    border: 7px solid #C25982;
  }

  #single-farmer-page-farmer-card .card-body{
    padding: 0px;
  }

  #news-item-page h1 {
    font-size: 24px;
    margin-top: 1rem;
  }

  #news-item-page p {
    font-size: 16px;
  }

  #news-item-page .news-item-date {
    display: none;
  }

  #news-item-page .news-page-related-items {
    display: none;
  }

  .items-tab .card-title {
    font-size: 14px;
  }

  .items-tab .card-deck .item-region {
    font-size: 10px;
  }

  .items-tab div.item-price span {
    font-size: 16px;
  }

  .items-tab div.item-price {
    font-size: 10px;
  }

  .items-tab .card-deck .card img {
    height: 100%;
  }

  .add-item-to-cart-modal .item-image-col {
    max-width: 50%;
  }

  .add-item-to-cart-modal .item-info-col {
    max-width: 50%;
  }

  .add-item-to-cart-modal .item-info-col .item-name {
    font-size: 14px;
    margin-top: 0px;
  }

  .add-item-to-cart-modal .item-info-col p {
    font-size: 12px;
  }

  .add-item-to-cart-modal .item-info-col .item-quantity label{
    font-size: 10px;
  }

  .add-item-to-cart-modal .item-info-col .item-quantity-col{
    flex-basis: auto;
  }

  .add-item-to-cart-modal .item-info-col .item-quantity select{
    width: 40%;
    font-size: 10px;
  }

  .add-item-to-cart-modal .item-info-col h5.item-price{
    font-size: 14px;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }

  .add-item-to-cart-modal .item-info-col h5.item-price span{
    font-size: 125%;
  }

  .add-item-to-cart-modal h5.item-price {
    margin-top: 40px
  }

  .mobile-item-farmer {
    margin-bottom: 1.5rem;
  }

  .settings-page .mr-3 {
    margin-right: 0px !important;
  }

  .settings-page .MuiAvatar-root {
    margin-left: -20%;
  }

  .settings-page .col-sm-9 {
    width: 100%;
  }

  .btn-checkout-market-list {
    font-size: 14px;
  }

  .checkout-page .order-disclaimer {
    font-size: 12px;
  }

  /* .checkout-page .checkout-card.cart-items {
    margin-top: 0px;
  } */

  .checkout-page .checkout-card.cart-items .item.list-group-item .pr-0{
    width: 40%;
    padding-right: 15px !important;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .col.item-info{
    padding-left: 0px;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .mobile-item-quantity{
    font-size: 14px;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .mobile-item-quantity select{
    font-size: 14px;
    max-width: 70%;
  }

  .item-icon-container {
    height: 158px;
  }

  /* .checkout-page .checkout-card.cart-items .item.list-group-item .col-sm-2.item-image{
    width: 30%;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .col.item-info .item-name{
    font-size: 14px;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .col.item-info .item-farmer{
    font-size: 12px;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .col.item-quantity{
    padding-left: 0px;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .col.item-quantity .float-right{
    float: left !important;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .col.item-quantity{
    font-size: 14px;
  }

  .checkout-page .checkout-card.cart-items .item.list-group-item .col.item-total-price{
    padding-left: 0px;
  } */

  .checkout-page .checkout-card.cart-items .item-total-price .price{
    margin-top: 50px;
    color: #C25982;
    font-size: 18px;
  }

  .checkout-page .checkout-card.cart-items .price{
    margin-top: 0px;
    color: #A9CB59;
  }

  .checkout-page .checkout-card.delivery-address {
    margin-top: 0px;
  }

  .hinzufügen-modal.change-address button {
    width: 100px;
  }

  .item-card-cart-icon-col {
    max-width: 30%;
  }

  #notifications-modal .notification-content .col-sm-1 {
    padding-left: 0px;
    padding-right: 4px;
  }

  .market-list-add-items .item-list-item.col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .market-list-add-items .item-list-item img {
    max-height: 130px;
  }

  .market-list-add-items .item-list-item .card-title {
    font-size: 14px;
  }
  
  .market-list-add-items .item-list-item .card-subtitle {
    font-size: 12px;
  }
  
  .market-list-add-items .item-list-item .card-text .item-price{
    font-size: 10px;
  }
  
  .market-list-add-items .item-list-item .card-text .item-price span{
    font-size: 16px;
  }

  #farmers-page .input-icon {
    top: 30px;
  }

  .settings-page .mr-3{
    flex-basis: auto;
  }

  .mobile-item-farmer .row .col {
    max-width: 90%;
  }

  .mobile-item-farmer .row svg {
    position: absolute;
    top: 15px;
  }

  .mobile-item-farmer .row svg path{
    fill: #C25982;
  }

  .add-item-to-cart-modal .item-quantity-selector {
    margin-top: 5px;
  }

  .add-item-to-cart-modal .item-quantity-selector p{
    margin-top: 5px;
  }

  .item-quantity-selector-col {
    padding: 0px;
    max-width: 10%;
    margin-right: 0.5rem;
  }

  .item-quantity-selector-col .item-quantity-selector{
    height: 100%;
  }

  .item-quantity-selector-col p{
    bottom: 20px;
    right: 12px;
  }

  .item-quantity-selector-col button.minus-btn{
    bottom: 0;
    right: 5px;
  }

  .item-quantity-selector-col button.plus-btn{
    right: 5px;
  }

  .checkout-page .item-quantity-selector-col p{
    bottom: 35px;
    right: 8px;
  }

  .checkout-page .item-quantity-selector-col button.plus-btn{
    right: 0;
  }

  .checkout-page .item-quantity-selector-col button.minus-btn{
    right: 0;
  }

  .item-quantity-col .item-quantity .col {
    height: 25px;
  }

  .single-order-card .mt-2 {
    margin-bottom: 6px;
  }

  .mobile-order-single-order-modal .modal-header {
    background-color: #C25982;
  }

  .login-page .p-4.custom-form {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(144, 144, 144)' stroke-width='2.5' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }

  .navbar-toggler {
    padding-left: 0px;
    padding-right: 0px;
  }

  .navbar {
    justify-content: end;
  }

  .navbar #mobile-navbar-logo {
    margin-right: auto;
  }

  .news-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .register-checkbox {
    font-size: 80%;
  }

  .already-a-member-modal svg{
    width: 75%;
    height: 75%;
  }

  .download-lieferschein-btn {
    margin-bottom: 1rem;
  }

  .checkout-card.delivery-address .active-delivery-address {
    max-width: 75%;
  }

  #admin-navbar-link {
    padding-left: 1rem;
  }

  nav.primary-nav img#desktop-navbar-logo {
    display: none
  }

  .mobile-navbar-register {
    text-align: center;
    background-color: #C25982;
    color: white !important;
    border-radius: 8px;
    padding-top: 2.5% !important;
    padding-bottom: 2.5% !important;
    margin-top: 1rem;
  }

  .farmer-item-quantity input {
    margin-bottom: 0.5rem;
  }

  .market-page .items-tab {
    margin-top: 0.25rem;
  }

  .admin-page-nav-2 {
    margin-top: 0.1rem;
  }

  .page {
    padding-top: 65px;
  }

  .back-button-row p {
    margin-bottom: 0.5rem;
  }

  .page-header-row {
    margin-bottom: 0.5rem !important;
  }

  .orders-tab {
    margin-top: 0.5rem;
  }

  #orders-page h3 {
    margin-bottom: 0.5rem;
  }

  .order-list-item-container {
    padding-bottom: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  #order-items-preview-container-1 {
    overflow: hidden;
  }

  #order-items-preview-container-1 img{
    margin-right: 1rem;
  }

  #order-items-preview-container-2 img{
    margin-right: 1rem !important;
  }

  /* #selected-order-card {
    margin-top: 1rem;
  } */

  #order-list-item-desktop {
    display: none;
  }

  #single-order-page svg, #single-marketlist-page svg  {
    margin-bottom: 0.25rem;
  }

  #single-order-page #selected-order-header-container {
    padding-top: 0.25rem !important;
  }

  #single-order-page #selected-order-header, #single-marketlist-page #selected-order-header {
    font-size: 36px !important;
    margin-bottom: -0.5rem !important;
  }

  #single-order-page #selected-order-number {
    font-size: 20px !important;
  }

  #single-order-page #selected-order-status {
    margin-top: 0.5rem !important;
  }

  #single-order-page #selected-order-details-container h3{
    font-size: 18px;
    font-weight: bold;
    color: black;
  }

  #single-order-page #selected-order-details-container .label-value {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #single-order-page #selected-order-details-container .label-value p {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  #single-order-page img, #single-marketlist-page img {
    max-width: 85%;
    border-radius: 8px;
  }

  #single-order-page p.label, #single-marketlist-page p.label {
    margin-bottom: 0px;
    font-size: 12px;
  }

  #single-marketlist-page #add-item-to-marketlist-button {
    margin-top: 20px;
  }

  #single-marketlist-page #add-item-to-marketlist-button svg {
    margin-bottom: 0px;
  }

  #help-page .contact-form {
    margin-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #settings-page-card {
    margin-top: 0px !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  #settings-page-save-button-col button {
    margin-bottom: 0.5rem;
  }

  #update-password-button {
    margin-top: 0.5rem !important;
  }

  #settings-page-card hr {
    margin-bottom: 0.5rem;
  }

  #settings-page-card #btn-logout {
    display: none;
  }

  #notifications-modal-close-button-row p{
    font-size: 25px;
    float: right;
    margin-right: 0.5rem;
  }

  .navbar .collapse .row, .navbar .collapsing .row {
    margin-left: 0px;
    width: 100% !important;
  }

  .collapse .mobile-navbar-item-label, .collapsing .mobile-navbar-item-label {
    display: inline;
    font-size: 16px;
    margin-left: 0.5rem;
  }

  .collapse #navbar-search-item svg, .collapsing #navbar-search-item svg {
    margin-bottom: 2.5px;
  }

  #navbar-search-item {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  #navbar-search-item-col-1 {
    max-width: 80% !important;
  }

  #navbar-search-item-col-1 input {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  
  #navbar-search-item-col-2 {
    max-width: 20% !important;
  }

  #navbar-notifications-item {
    width: 100% !important;
    border: none !important;
    height: 40px !important;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
  }

  #navbar-settings-item {
    width: 100% !important;
    border: none !important;
    height: 40px !important;
    padding-top: 0.5rem;
    margin-bottom: 1rem;
  }

  #navbar-account-item {
    height: 40px !important;
    padding-top: 0.5rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  #mobile-navbar-settings-icon {
    width: 20px;
    height: 20px;
  }

  #mobile-navbar-settings-icon path{
    stroke: #909090;
  }

  #btn-logout {
    margin-top: 0px;
    text-align: center;
    font-size: 16px;
  }

  .collapse #navbar-cart-item, .collapsing #navbar-cart-item {
    display: none;
  }

  #settings-modal {
    max-width: none !important;
  }

  #settings-modal .icon-col {
    max-width: 40% !important;
    text-align: right !important;
    padding-right: 1rem !important;
  }

  #settings-modal .label-col {
    max-width: 60% !important;
  }

  #settings-modal p {
    font-size: 16px !important;
  }

  #market-filter-col {
    display: none;
  }

  .market-page h2 {
    font-size: 20px;
    font-weight: bold;
    color: black;
    margin-bottom: 1rem;
  }

  #mobile-market-categories-container {
    margin-bottom: 0.5rem;
  }

  #mobile-market-categories-container .card p{
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    color: black;
    text-align: center;
  }

  #mobile-market-categories-container .card p.active{
    color: #C25982;
  }

  #mobile-market-categories-container .card .category-image {
    border-radius: 6px;
    width: 100%;
  }

  #mobile-market-categories-container .card {
    padding: 0.5rem;
    width: 32%;
    margin-bottom: 0.5rem;
  }

  #mobile-market-categories-container .card.active {
    background-color: #FFEAF2;
  }

  #marketlists-container {
    margin-top: 0.5rem !important;
  }

  /* #marketlists-container .col {
    padding-left: 0px;
    padding-right: 0px;
  } */

  #single-item-page .container {
    margin-top: 0.5rem !important;
  }

  #single-item-page .item-image-col {
    flex-basis: auto;
    max-width: none;
  }

  #single-item-page .item-image-col img {
    max-height: none !important;
  }

  #single-item-page .item-info-col {
    flex-basis: auto;
    max-width: none;
    padding-top: 15px !important;
  }

  #single-item-page .item-info-col .item-quantity-col .item-quantity p {
    margin-bottom: 0.25rem;
  }

  #single-item-page .item-info-col .item-quantity-col .item-quantity .col {
    max-width: 40%;
  }

  #mobile-single-item-accordion-container {
    margin-bottom: 5rem;
  }

  #mobile-single-item-accordion-container .col {
    padding-left: 0px;
    padding-right: 0px;
  }

  #mobile-single-item-accordion-container p {
    margin-bottom: 0px;
  }

  #mobile-single-item-accordion-container p.accordion-header {
    font-size: 13px;
    font-weight: 500;
    color: #616161;
  }

  #mobile-single-item-accordion-container p.accordion-text {
    font-size: 11px;
    font-weight: 500;
    color: #9A9A9A;
  }
  
  #mobile-single-item-accordion-container .MuiPaper-root {
    box-shadow: none;
    margin: 0px;
  }

  #mobile-single-item-accordion-container .MuiPaper-root::before {
    background-color: transparent;
  }

  .form-input input {
    border: 1px solid #D2D2D7 !important;
  }

  .item-list-row {
    margin: 0;
    width: 100%;
    justify-content: space-between;
  }
}
